import * as React from "react"

function NotuseForm() {

    return (

        <form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="dynamic-feedback">
            <input type="hidden" name="form-name" value="dynamic-feedback"></input>
            <div className="place-items-center ">
                <div className="max-w-full mb-8">
                    <label className="flex text-left mt-5">How was your experience with Happen?</label>
                    <div className="form-radio">
                        <label><input name="experience" type="radio" value="OK" /><span role="img" aria-label="grin" className="mr-2">😁</span>OK</label>
                        <label><input name="experience" type="radio" value="NotOK"/><span role="img" aria-label="frowning_face" className="mr-2">☹️</span> Not OK</label>
                        <label><input name="experience" type="radio" value="Suggestion"/><span role="img" aria-label="bulb" className="mr-2">💡</span>Suggestion</label>
                    </div>
                    <div id="wentWell">
                        <h2 className="font-semibold text-left text-[18px] mt-5">Give us a compliment</h2>
                        <label className="flex text-left mt-5">wentWell</label>
                        <input
                        type="text"
                        id="well"
                        name="well"
                        className="mb-5 focus:outline-none w-full pb-[120px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px] "
                        />
                    </div>
                    <div id="wentWrong">
                        <h2 className="font-semibold text-left text-[18px] mt-5">Tell us about your complaint</h2>
                        <label className="flex text-left mt-5">wentWrong</label>
                        <input
                        type="text"
                        id="wrong"
                        name="wrong"
                        className="mb-5 focus:outline-none w-full pb-[120px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px] "
                        />
                    </div>
                    <div id="suggestion">
                        <h2 className="font-semibold text-left text-[18px] mt-5">Make a suggestion</h2>
                        <label className="flex text-left mt-5">improve</label>
                        <input
                            type="text"
                            id="improve"
                            name="improve"
                            className="mb-5 focus:outline-none w-full pb-[120px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px] "
                        />
                        <label className="flex text-left mt-5">Can we contact you if we have more questions?</label>
                        <div className="form-radio">
                            <label><input name="more-questions" id="more-questions" type="radio" value="Yes" />Yes</label>
                            <label><input name="more-questions" id="more-questions" type="radio" value="No" />No</label>
                        </div>
                    </div>
                    <div id="contact">
                        <h2 className="font-semibold text-left text-[18px] mt-5">Contact</h2>
                        <label className="flex text-left mt-5">email *</label>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        data-required="true"
                        required
                        className="mb-5 focus:outline-none w-full pb-[18px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px]  "
                        />
                        <div>
                            <button className="flex items-center rounded-[7px] font-semibold text-white  header-btn capitalize px-8 py-2 mt-3" type="submit">
                            send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default NotuseForm;